.primaryBanner {
  padding: 0 10vw;
  flex-direction: row;
  display: flex;
  margin: 4vh 0;
}

.primaryBannerContainer {
  position: relative;
}

.primaryBannerImage {
  width: auto;
  height: 30rem;
  object-fit: contain;
  border-radius: 0 10vh 0 6vh;
  display: block;
  margin: 0 auto;
  margin-left: 4vw;
}

.pho {
  height: 9rem;
  object-fit: contain;
  position: absolute;
  bottom: 2rem;
  right: 16rem;
}

.goicuon {
  height: 9rem;
  object-fit: contain;
  position: absolute;
  bottom: 16rem;
  right: -6rem;
}

.textContainer {
  padding: 0 10% 0 0;
}

.textContainer h2 {
  color: var(--yellow-color);
  margin-bottom: 4vh;
  font-weight: 600;
  font-style: oblique;
  font-size: 2.3rem;
  font-family:Arial, Helvetica, sans-serif;
}

.textContainer h3 {
  color: #306c3c;
  font-weight: 400;
}

@media (max-width: 768px) {
  .primaryBanner {
    flex-direction: column;
    padding: 0 1rem;
    margin-bottom: 0;
  }

  .textContainer {
    margin-bottom: 3rem;
    padding: 0;
  }

  .textContainer h2 {
    font-size: 1.6rem;
    margin-bottom: 4vh;
  }

  .primaryBannerContainer {
    align-self: center;
}

  .primaryBannerImage {
    height: 25rem;
    /* height: auto; */
    object-fit: contain;
    border-radius: 0 10vh 0 6vh;
    display: block;
    margin: 0 auto;
  }

  .pho {
    height: 6rem;
    object-fit: contain;
    position: absolute;
    bottom: 0.8rem;
    right: 12.6rem;

  }

  .goicuon {
    height: 7rem;
    object-fit: contain;
    position: absolute;
    bottom: 12rem;
    right: -3rem;
  }
}
