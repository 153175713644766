.container {
  display: flex;
  background-color: var(--background-color);
  padding: 2rem 3rem;
  justify-content: space-around;
}

.container h3,
h2,
h4,
a {
  white-space: pre-wrap;
  color: var(--primary);
}

.restaurantName{
  color: var(--yellow-color);
}

.container h2,
h3 {
  font-weight: bold;
}

.address {
  margin: 0 5% 0 0;
}

.openingTimeWrapper {
  margin: 0 5% 0 0;
  display: flex;
  flex-direction: column;
}

.openingTimeContainer {
  display: flex;
  flex-direction: column;
}

.openingTimeContainer h3 {
  margin: 4px 0;
  font-weight: lighter;
  font-size: clamp(1vw, 1.2vw, 1.8vw);
  color: var(--primary);
  text-align: left;
}

.openingTime {
  display: flex;
  justify-content: space-between;
  max-width: 28vw;
  white-space: pre-wrap;
}

.contactContainer {
  flex-direction: column;
  margin: 0 5% 0 0;
}

.contactContainer img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: 10px;
}

.columnInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fbPage {
  width: 30rem;
  height: 20rem;
}

.marginBottom {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 0 3vw 1rem 3vw;
  }

  .container h2 {
    font-size: 9vw;
  }

  .container h4,
  a,
  h3 {
    font-size: 4vw;
  }

  .openingTimeWrapper {
    margin-top: 1vh;
    display: flex;
    margin: 0;
  }

  .address {
    margin: 0;
  }

  .openingTimeContainer {
    display: flex;
    flex-direction: column;
  }

  .openingTimeContainer h3 {
    font-size: clamp(1vw, 4vw, 7vw);
  }

  .contactContainer {
    margin-top: 3vh;
  }

  .openingTime {
    width: auto;
    max-width: 100%;
  }
}
