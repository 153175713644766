.introduction {
    padding: 0 0 3vh 0;
    background-color: var(--primary);
}

.introduction:-webkit-scrollbar {
    width: 0; /* Set scrollbar width to 0 */
    height: 0; /* Set scrollbar height to 0 */
}

.rowWrapper {
    display: flex;
    flex-direction: row;
    background-color: var(--primary);
    padding: 6vh 5vw;
}

.introTextContainer {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.introTextContainer h1 {
    font-weight: bold;
    color: var(--primary);
    text-align: center;
}

.greyIntroTextContainer {
    color: var(--offwhite-color);
    text-align: center;
    line-height: 27px
}

.introTextContainer h3 {
    color: var(--yellow-color);
}

.introImg {
    width: 28vw;
    height: 65vh;
    object-fit: cover;
    margin: 0 2vw 0 0;
}

.introHeader {
    justify-content: center;
    align-items: center;
    padding: 4vh 30vw 0 30vw;
}

.cursiveText {
    font-family: Alex Brush, cursive;
    font-size: 3rem;
    margin: 0;
    color: var(--yellow-color);
    text-align: center;
}

.introductionText {
    font-weight: 500;
    text-align: center;
}

.cursiveName {
    font-family: Alex Brush, cursive;
    font-size: 1.6rem;
    margin: 0;
    color: var(--yellow-color);
    display: inline-block;
}

@media (max-width: 768px) {
    .introduction {
        padding: 8vh 0 0 0;
        margin-top: -8vh;
    }

    .rowWrapper {
        flex-direction: column;
        padding: 0 0 3vh 0;
    }

    .introHeader {
        padding: 1vh 8vw;
    }

    .introTextContainer {
        width: 85vw;
        align-items: center;
        align-self: center;
    }

    .introTextContainer h1 {
        text-align: center;
        font-size: 1rem;
    }

    .introTextContainer h3 {
        text-align: center;
    }

    .cursiveText {
        font-size: 2.3rem;
        margin: 0;
        text-align: center;
    }

    .cursiveName {
        font-family: Alex Brush, cursive;
        font-size: 1.4rem;
        margin: 0;
    }

    .introductionText {
        display: inline-block;
    }

    .greyIntroTextContainer {
        text-align: left;
        line-height: 20px;
        font-size: 1rem;
    }

    .introImg {
        width: auto;
        height: 50vh;
        object-fit: contain;
        margin: 3vh 0 0 0;
        border-radius: 0 0 20px 0;
        align-self: center;
    }
}
