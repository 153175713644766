.bookNowBtn {
  background-color: var(--primary);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 1vh 0.7vw;
  width: 12vw;
  min-height: 2.8rem;
  min-width: 5rem;
  transition: background-color 0.5s ease-in-out;
  flex-direction: row;
  display: flex;

  /*&:hover h3.change-color-on-hover {*/
  /*     color: var(--blue-strong-color);*/
  /*     transition: color 0.2s ease-in-out;*/
  /* }*/
}

.bookNowBtn:hover {
  background-color: var(--yellow-color);
  cursor: pointer;
}

.bookNowBtn:hover h3 {
  color: var(--background-color);
  transition: color 0.2s ease-in-out;
}

.bookNowBtn h3 {
  color: #fff;
  margin: 0 1vw 0 0;
  font-size: small;
  font-weight: bold;
}

.bookNowBtn img {
  width: 1.5vw;
  height: 1.5vw;
  transform: rotate(180deg);
}

.button {
  background-color: transparent;
  padding: 0;
  border: 0;
}

@media (max-width: 768px) {
  .bookNowBtn {
    min-height: 2.8rem;
    min-width: 7.5rem;
  }

  .bookNowBtn h3 {
    margin: 0 2vw 0 0;
  }

  .bookNowBtn img {
    width: 3vw;
    height: 3vw;
  }
}
