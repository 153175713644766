.introduction {
  margin: 0 0;
  background-color: #fff;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  padding: 7vh 5vw;
}

.introTextContainer {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.introTextContainer h2 {
  color: var(--yellow-color);
  text-align: center;
  font-weight: bold;
}

.introTextContainer p {
  color: var(--primary);
  text-align: center;
  line-height: 27px;
}

.wideImgContainer {
  display: flex;
  flex-direction: column;
  height: 48vh;
  justify-content: space-between;
}

.wideImg {
  height: 20vh;
  width: 20vw;
  flex: 1;
  object-fit: cover;
}

.separator {
  height: 0.5rem;
}

.imageRow {
  display: flex;
  flex-direction: row;
  position: relative;
}

.introImg {
  height: 38rem;
  object-fit: contain;
  border-radius: 50px 0 50px 0;
  margin: 0 4vw 0 10vw;
}

.introSubDrinkImg {
  position: absolute;
  height: 10rem;
  object-fit: contain;
  right: 26rem;
  top: -3rem;
}

.introSubImg {
  position: absolute;
  height: 8rem;
  object-fit: contain;
  right: -3rem;
  top: 34rem;
}

@media (max-width: 768px) {
  .rowWrapper {
    flex-direction: column-reverse;
    padding: 0 0 2vh 0vw;
  }

  .wideImgContainer {
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: space-between;
  }

  .wideImg {
    height: 12vh;
    width: 49vw;
  }

  .introTextContainer {
    padding: 0 6vw;
  }

  .introTextContainer h2 {
    text-align: center;
  }

  .introTextContainer p {
    text-align: center;
    line-height: 23px;
    font-size: 1rem;
  }

  .imageRow {
    width: 23rem;
    align-self: center;
    margin: 4rem 0;
  }

  .introImg {
    height: 25rem;
    width: auto;
    object-fit: contain;
    border-radius: 60px 0 45px 0;
    /* margin: 2rem auto 2rem 18vw; */
    display: block;
    margin: 0 auto;
  }

  .introSubDrinkImg {
    position: absolute;
    height: 6rem;
    object-fit: contain;
    right: 17.8rem;
    top: -2rem;
  }

  .introSubImg {
    height: 7rem;
    object-fit: contain;
    position: absolute;
    right: -0.8rem;
    top: 18rem;
  }
}
